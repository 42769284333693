
import { GoogleDriveLink } from "@/services/authService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  data() {
    return {
      loading: true,
      success: false,
    };
  },
  computed: {
    message(): any {
      if (this.success) {
        return "Account successfully linked. You may close this tab.";
      }
      return "Something went wrong while linking to Google Drive. Try again later.";
    },
  },
  mounted() {
    const url = new URL(location.href);
    const token = url.searchParams.get("state");
    const code = url.searchParams.get("code");
    if (!token || !code) {
      this.loading = false;
      return;
    }
    GoogleDriveLink(code, token)
      .then(() => {
        this.success = true;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.success = false;
      });
  },
});
